import { useContext, useState } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { ExclamationCircleIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'

import ModalContainer from 'components/ModalContainer'
import { Card, Button } from 'components/gsys-ui'

const DeleteModal = NiceModal.create(({ handleDelete, name, text }) => {
  const modal = useModal()
  const [isDeleting, setDeleting] = useState(false)

  const handleClickDelete = async () => {
    setDeleting(true)
    await handleDelete()
    modal.remove()
  }

  return (
    <ModalContainer>
      <Card className="!w-[350px]">
        <div className="flex flex-col items-center p-3 w-full">
          <div className="flex justify-center items-center mb-2 w-12 h-12 bg-red-100 rounded-full">
            <ExclamationCircleIcon className="w-8 h-8 text-red-400" />
          </div>
          <div className="text-lg font-bold">Delete {name}?</div>
          <div className="mb-4">{text ? text : "This can't be undone!"}</div>
          <div className="flex space-x-4 w-full">
            <Button
              variant="white"
              className="flex-1 justify-center"
              onClick={() => modal.remove()}
            >
              <XMarkIcon className="mr-1 w-5 h-5" />
              Cancel
            </Button>
            <Button
              variant="red"
              loading={isDeleting}
              className="flex-1 justify-center"
              onClick={handleClickDelete}
            >
              <TrashIcon className="mr-1 w-5 h-5" />
              Delete
            </Button>
          </div>
        </div>
      </Card>
    </ModalContainer>
  )
})

export default DeleteModal
